<template>
<div class="px-3">
    <h1 class="my-4"><span class="grey--text">Ви шукали: «</span>{{$route.query.query}}<span class="grey--text">»</span></h1>
    <div class="grey--text mb-4 mb-sm-0">
        <div>Знайдено <span class="second--text">{{ catalogListTotal }} товарів</span></div>
    </div>
    <div>
        <List search :isGuest="isGuest" v-if="!loading"/>
    </div>
</div>
</template>

<script>
import List from './List.vue'

import {
    mapActions,
} from 'vuex'

export default {
    components: {
        List
    },
    data: () => ({
        isGuest: false,
        loading: false
    }),
    props: {},
    methods: {
        ...mapActions(['touch', 'getCatalogListArchive','getContactInfo']),
    },
    created() {
        this.touch()
        this.loading = true
            this.getContactInfo()
                .then(data => {
                    this.isGuest = data.isGuest ? true : false
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })

            .then(() => this.getCatalogListArchive({
                query: this.$route.query.query
            }))
            .catch(error => console.error(error))
    },
    computed: {
        catalogListTotal() {
            return this.$store.state.catalog.catalogListTotal
        },
    },
    watch: {
        '$route.query.query'() {
            this.$store.commit('setCatalogListArchive', [])
            this.catalogListArchiveTotal = 0
            this.touch()
                .then(() => this.getCatalogListArchive({
                    query: this.$route.query.query
                }))
                .catch(error => console.error(error))
        }
    }
}
</script>
